var render = function () {
  var _vm$selectedListClien, _vm$$route$params, _vm$$route$params2, _vm$$route$params3, _vm$client, _vm$client$firstName, _vm$client2, _vm$client3, _vm$client$phone, _vm$client4, _vm$client5, _vm$selectedListClien2, _vm$selectedListClien3, _vm$selectedListClien4, _vm$selectedListClien5, _vm$selectedListClien6, _vm$selectedListClien7, _vm$selectedListClien8, _vm$selectedListClien9, _vm$selectedListClien10, _vm$selectedListClien11, _vm$selectedListClien12, _vm$selectedListClien13;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', {
    attrs: {
      "w": "100%",
      "max-width": "1270px",
      "mx": "auto",
      "bg": "#FFF",
      "border": "1px solid #f2f2f2",
      "box-shadow": ['none', '2px 2px 10px rgba(0, 0, 0, 0.15)'],
      "border-radius": ['0px', '16px'],
      "p": ['16px', '30px'],
      "min-height": ['unset', '74vh']
    }
  }, [_c('Portal', {
    attrs: {
      "to": "breadcrumb"
    }
  }, [_c('BreadcrumbPath', {
    attrs: {
      "padding": "20px 90px",
      "paths": _vm.breadcrumbPath
    }
  })], 1), ['review', 'revisi', 'sent', 'read'].includes((_vm$selectedListClien = _vm.selectedListClientMealPlanDetail) === null || _vm$selectedListClien === void 0 ? void 0 : _vm$selectedListClien.status) ? _c('DownloadMealPlan', {
    attrs: {
      "program-id-local": (_vm$$route$params = _vm.$route.params) === null || _vm$$route$params === void 0 ? void 0 : _vm$$route$params.programId,
      "month": (_vm$$route$params2 = _vm.$route.params) === null || _vm$$route$params2 === void 0 ? void 0 : _vm$$route$params2.month,
      "client-id-local": (_vm$$route$params3 = _vm.$route.params) === null || _vm$$route$params3 === void 0 ? void 0 : _vm$$route$params3.clientId
    }
  }) : _vm._e(), _c('c-flex', {
    attrs: {
      "flex-direction": "column",
      "width": "100%",
      "gap": "16px"
    }
  }, [_c('c-flex', {
    attrs: {
      "w": "100%",
      "gap": "20px",
      "align-items": "center"
    }
  }, [_c('c-image', {
    attrs: {
      "w": ['80px'],
      "h": ['80px'],
      "object-fit": "cover",
      "border-radius": "80px",
      "src": _vm.getPhotoUser((_vm$client = _vm.client) === null || _vm$client === void 0 ? void 0 : _vm$client.photoUrl)
    }
  }), _c('c-box', {
    attrs: {
      "w": "100%"
    }
  }, [_c('c-text', {
    attrs: {
      "font-size": ['18px'],
      "font-weight": "500",
      "color": "neutral.black",
      "margin-bottom": "8px"
    }
  }, [_vm._v(" " + _vm._s((_vm$client$firstName = (_vm$client2 = _vm.client) === null || _vm$client2 === void 0 ? void 0 : _vm$client2.firstName) !== null && _vm$client$firstName !== void 0 ? _vm$client$firstName : '...') + " " + _vm._s((_vm$client3 = _vm.client) === null || _vm$client3 === void 0 ? void 0 : _vm$client3.lastName) + " ")]), _c('c-text', {
    attrs: {
      "font-size": ['14px'],
      "font-weight": "400",
      "color": "primary.400"
    }
  }, [_vm._v(" " + _vm._s((_vm$client$phone = (_vm$client4 = _vm.client) === null || _vm$client4 === void 0 ? void 0 : _vm$client4.phone) !== null && _vm$client$phone !== void 0 ? _vm$client$phone : '...') + " ")])], 1)], 1), _c('c-flex', {
    attrs: {
      "width": "100%",
      "justify-content": "space-between",
      "align-items": "center"
    }
  }, [_c('c-button', {
    attrs: {
      "variant-color": "primary",
      "border-radius": "30px",
      "font-size": "18px",
      "padding": "0px 20px",
      "font-weight": "600",
      "is-disabled": !((_vm$client5 = _vm.client) !== null && _vm$client5 !== void 0 && _vm$client5.id)
    },
    on: {
      "click": _vm.onOpenQuestionnaire
    }
  }, [_vm._v(" Formulir Gizi ")]), _c('c-menu', {
    attrs: {
      "auto-select": false
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var _vm$$route$params4;

        var isOpen = _ref.isOpen;
        return [_c('c-menu-button', {
          attrs: {
            "variant": "outline",
            "variant-color": "primary",
            "font-weight": "500",
            "font-size": "18px",
            "gap": "14px",
            "padding-inline": "20px",
            "border-radius": "40px"
          }
        }, [_vm._v(" Panduan Makan Bulan ke-" + _vm._s(((_vm$$route$params4 = _vm.$route.params) === null || _vm$$route$params4 === void 0 ? void 0 : _vm$$route$params4.month) || 'x') + " "), _c('c-box', {
          attrs: {
            "width": "14px",
            "height": "14px",
            "transform": isOpen ? 'rotate(180deg)' : 'rotate(0deg)'
          }
        }, [_c('inline-svg', {
          attrs: {
            "src": require('@/assets/icons/icon-arrow-down.svg'),
            "height": "100%",
            "width": "100%",
            "fill": "#008C81"
          }
        })], 1)], 1), _c('c-menu-list', {
          attrs: {
            "padding": "0px"
          }
        }, _vm._l(_vm.optionListClientMealPlan, function (opt) {
          return _c('c-menu-item', {
            key: opt.month,
            attrs: {
              "padding": "8px 16px",
              "font-weight": "500",
              "font-size": "18px",
              "background-color": opt.month === _vm.month ? 'primary.50' : 'neutral.white',
              "is-disabled": opt.isDisabled
            },
            on: {
              "click": function click($event) {
                return _vm.onChangeMonth(opt);
              }
            }
          }, [_vm._v(" Panduan Makan Bulan ke-" + _vm._s(opt.month) + " ")]);
        }), 1)];
      }
    }])
  })], 1)], 1), _c('c-box', {
    attrs: {
      "margin-top": "16px",
      "width": "100%"
    }
  }, [_c('c-flex', {
    attrs: {
      "background-color": "#F3B965",
      "padding": "8px 16px",
      "justify-content": "space-between",
      "align-items": "center",
      "border-top-left-radius": "8px",
      "border-top-right-radius": "8px"
    }
  }, [_c('c-text', {
    attrs: {
      "font-size": "18px",
      "font-weight": "500"
    }
  }, [_vm._v(" Status Panduan Makan ")]), _c('c-text', {
    attrs: {
      "font-size": "16px",
      "font-weight": "400",
      "text-transform": "capitalize"
    }
  }, [_vm._v(" " + _vm._s(((_vm$selectedListClien2 = _vm.selectedListClientMealPlanDetail) === null || _vm$selectedListClien2 === void 0 ? void 0 : _vm$selectedListClien2.status) || '-') + " ")])], 1), _c('c-grid', {
    attrs: {
      "height": "100%",
      "width": "100%",
      "min-width": "600px",
      "template-columns": "auto 1fr",
      "padding": "12px 32px",
      "background-color": "#FFFCDD"
    }
  }, [_c('c-grid-item', {
    attrs: {
      "border-bottom": "1px solid #C4C4C4",
      "display": "flex",
      "align-items": "center",
      "padding": "16px 0"
    }
  }, [_c('c-text', {
    attrs: {
      "font-weight": "400",
      "font-size": "16px"
    }
  }, [_vm._v(" Client Manager ")])], 1), _c('c-grid-item', {
    attrs: {
      "border-bottom": "1px solid #C4C4C4",
      "display": "flex",
      "align-items": "center",
      "padding": "16px 0 16px 40px"
    }
  }, [_c('BaseInputAutocomplete', {
    attrs: {
      "placeholder": "Pilih Client Manager",
      "full-width": "",
      "is-without-margin-bottom": "",
      "is-required": "",
      "options": _vm.optionListClientManager,
      "is-disabled": ['review', 'sent', 'read'].includes((_vm$selectedListClien3 = _vm.selectedListClientMealPlanDetail) === null || _vm$selectedListClien3 === void 0 ? void 0 : _vm$selectedListClien3.status)
    },
    model: {
      value: _vm.selectedListClientMealPlanDetail.clientManagerId,
      callback: function callback($$v) {
        _vm.$set(_vm.selectedListClientMealPlanDetail, "clientManagerId", $$v);
      },
      expression: "selectedListClientMealPlanDetail.clientManagerId"
    }
  })], 1), _c('c-grid-item', {
    attrs: {
      "border-bottom": "1px solid #C4C4C4",
      "display": "flex",
      "align-items": "center",
      "padding": "16px 0"
    }
  }, [_c('c-text', {
    attrs: {
      "font-weight": "400",
      "font-size": "16px"
    }
  }, [_vm._v(" Terakhir Dikirim ")])], 1), _c('c-grid-item', {
    attrs: {
      "border-bottom": "1px solid #C4C4C4",
      "padding-left": "16px",
      "display": "flex",
      "align-items": "center",
      "padding": "16px 0 16px 40px"
    }
  }, [_c('c-text', [_vm._v(" " + _vm._s((_vm$selectedListClien4 = _vm.selectedListClientMealPlanDetail) !== null && _vm$selectedListClien4 !== void 0 && _vm$selectedListClien4.createdAt ? _vm.formatDateV2((_vm$selectedListClien5 = _vm.selectedListClientMealPlanDetail) === null || _vm$selectedListClien5 === void 0 ? void 0 : _vm$selectedListClien5.createdAt, 7) : '-') + " ")])], 1), _c('c-grid-item', {
    attrs: {
      "border-bottom": "1px solid #C4C4C4",
      "display": "flex",
      "padding": "16px 0"
    }
  }, [_c('c-text', {
    attrs: {
      "font-weight": "400",
      "font-size": "16px"
    }
  }, [_vm._v(" Catatan Client Manager ")])], 1), _c('c-grid-item', {
    attrs: {
      "border-bottom": "1px solid #C4C4C4",
      "display": "flex",
      "align-items": "center",
      "padding": "16px 0 16px 40px",
      "transition": "all 1s"
    }
  }, [_c('c-text', {
    ref: "ooa12",
    class: _vm.readMore ? '' : 'line-clamp-1',
    attrs: {
      "width": "100%"
    }
  }, [_c('p', {
    domProps: {
      "innerHTML": _vm._s(['revision', 'revisi'].includes((_vm$selectedListClien6 = _vm.selectedListClientMealPlanDetail) === null || _vm$selectedListClien6 === void 0 ? void 0 : _vm$selectedListClien6.status) ? (_vm$selectedListClien7 = _vm.selectedListClientMealPlanDetail) === null || _vm$selectedListClien7 === void 0 ? void 0 : _vm$selectedListClien7.note : '-')
    }
  }), _vm.isEllipsis && !!_vm.readMore ? _c('c-button', {
    attrs: {
      "variant": "link",
      "font-weight": "400",
      "color": "primary.400",
      "display": "contents"
    },
    on: {
      "click": function click($event) {
        _vm.readMore = !_vm.readMore;
      }
    }
  }, [_vm._v(" Kecilkan ")]) : _vm._e()], 1), _vm.isEllipsis && !_vm.readMore ? _c('c-button', {
    attrs: {
      "variant": "link",
      "display": "contents",
      "font-weight": "400",
      "color": "primary.400"
    },
    on: {
      "click": function click($event) {
        _vm.readMore = !_vm.readMore;
      }
    }
  }, [_vm._v(" Selengkapnya ")]) : _vm._e()], 1)], 1), ['revision', 'revisi', 'draft'].includes((_vm$selectedListClien8 = _vm.selectedListClientMealPlanDetail) === null || _vm$selectedListClien8 === void 0 ? void 0 : _vm$selectedListClien8.status) ? _c('c-flex', {
    attrs: {
      "background-color": "#FFFCDD",
      "align-items": "center",
      "flex-direction": "column"
    }
  }, [_c('c-box', {
    attrs: {
      "padding-top": "3px"
    }
  }, [_c('c-text', {
    attrs: {
      "font-size": ['15px', '18px'],
      "font-weight": "500"
    }
  }, [_vm._v(" Catatan Ahli Gizi ")])], 1), _c('c-box', {
    attrs: {
      "padding-top": "8px",
      "padding-inline": "16px"
    }
  }, [_c('BaseEditor', {
    attrs: {
      "id": "client-meal-plan",
      "image-provider-url": "/v1/nutritionist/meal-plans/upload",
      "with-margin": false
    },
    model: {
      value: _vm.note,
      callback: function callback($$v) {
        _vm.note = $$v;
      },
      expression: "note"
    }
  })], 1)], 1) : _vm._e(), _c('c-flex', {
    attrs: {
      "gap": "8px",
      "justify-content": "flex-end",
      "background-color": "#FFFCDD",
      "padding": "12px 32px",
      "border-bottom-left-radius": "8px",
      "border-bottom-right-radius": "8px"
    }
  }, [_c('ModalDuplicateMealPlan', {
    attrs: {
      "id": (_vm$selectedListClien9 = _vm.selectedListClientMealPlanDetail) === null || _vm$selectedListClien9 === void 0 ? void 0 : _vm$selectedListClien9.id,
      "disabled": !['sent', 'read'].includes((_vm$selectedListClien10 = _vm.selectedListClientMealPlanDetail) === null || _vm$selectedListClien10 === void 0 ? void 0 : _vm$selectedListClien10.status),
      "list-month": _vm.optionListClientMealPlan
    }
  }), _c('c-button', {
    attrs: {
      "variant-color": "primary",
      "font-size": "18px",
      "font-weight": "500",
      "border-radius": "calc(infinity * 1px)",
      "width": "160px",
      "size": "lg",
      "disabled": ((_vm$selectedListClien11 = _vm.selectedListClientMealPlanDetail) === null || _vm$selectedListClien11 === void 0 ? void 0 : _vm$selectedListClien11.status) === 'review' || ((_vm$selectedListClien12 = _vm.selectedListClientMealPlanDetail) === null || _vm$selectedListClien12 === void 0 ? void 0 : _vm$selectedListClien12.status) === 'sent' || ((_vm$selectedListClien13 = _vm.selectedListClientMealPlanDetail) === null || _vm$selectedListClien13 === void 0 ? void 0 : _vm$selectedListClien13.validation) !== 1 || !_vm.selectedListClientMealPlanDetail.clientManagerId || !_vm.isValidOnEdit || !_vm.note
    },
    on: {
      "click": function click($event) {
        _vm.isOpenModalSendToClientManager = true;
      }
    }
  }, [_vm._v(" Kirim ")])], 1)], 1), _c('router-view', {
    attrs: {
      "is-edited": _vm.isEdited,
      "is-program-consultation-basic": _vm.isProgramConsultationBasic
    }
  }), _c('ModalContentQuestionnaire', {
    attrs: {
      "is-open": _vm.isOpenQuestionnaire
    },
    on: {
      "close": _vm.onCloseQuestionnaire
    }
  }), _c('ModalSimpleInfo', {
    attrs: {
      "is-open": _vm.isOpenModalSendToClientManager,
      "image": require('@/assets/images/image-question.svg'),
      "title": "Setelah dikirim ke Client Manager data pada panduan makan tidak dapat diubah kembali"
    },
    scopedSlots: _vm._u([{
      key: "footer",
      fn: function fn() {
        return [_c('c-flex', {
          attrs: {
            "gap": "20px",
            "width": "100%",
            "padding": "0 24px",
            "margin-bottom": "24px"
          }
        }, [_c('BaseButton', {
          attrs: {
            "left-svg-icon": require('@/assets/icons/icon-circle-close.svg'),
            "left-svg-icon-color": "#008C81",
            "variant": "outlined",
            "rounded": "1000px",
            "width": "100%",
            "disabled": _vm.isLoadingSendToClientManager
          },
          on: {
            "click": function click($event) {
              _vm.isOpenModalSendToClientManager = false;
            }
          }
        }, [_vm._v(" Batal ")]), _c('BaseButton', {
          attrs: {
            "right-svg-icon": require('@/assets/icons/icon-circle-check.svg'),
            "right-svg-icon-color": "white",
            "color": "primary",
            "rounded": "1000px",
            "width": "100%",
            "disabled": _vm.isLoadingSendToClientManager
          },
          on: {
            "click": function click($event) {
              var _vm$selectedListClien14, _vm$selectedListClien15;

              return _vm.handleSendToClientManager({
                id: (_vm$selectedListClien14 = _vm.selectedListClientMealPlanDetail) === null || _vm$selectedListClien14 === void 0 ? void 0 : _vm$selectedListClien14.id,
                clientManagerId: (_vm$selectedListClien15 = _vm.selectedListClientMealPlanDetail) === null || _vm$selectedListClien15 === void 0 ? void 0 : _vm$selectedListClien15.clientManagerId
              });
            }
          }
        }, [_vm._v(" Konfirmasi ")])], 1)];
      },
      proxy: true
    }])
  }), _c('ModalSimpleInfo', {
    attrs: {
      "is-open": _vm.isOpenModalSuccessSaveData2,
      "image": require('@/assets/images/image-question.svg'),
      "title": "Data [Profil Gizi/Rencana Makan] akan diduplikasike Panduan Makan Bulan ke-X"
    },
    scopedSlots: _vm._u([{
      key: "footer",
      fn: function fn() {
        return [_c('c-flex', {
          attrs: {
            "gap": "20px",
            "width": "100%",
            "padding": "0 24px",
            "margin-bottom": "24px"
          }
        }, [_c('BaseButton', {
          attrs: {
            "left-svg-icon": require('@/assets/icons/icon-circle-close.svg'),
            "left-svg-icon-color": "#008C81",
            "variant": "outlined",
            "rounded": "1000px",
            "width": "100%"
          },
          on: {
            "click": function click($event) {
              _vm.isOpenModalSuccessSaveData2 = false;
            }
          }
        }, [_vm._v(" Batal ")]), _c('BaseButton', {
          attrs: {
            "right-svg-icon": require('@/assets/icons/icon-circle-check.svg'),
            "right-svg-icon-color": "white",
            "color": "primary",
            "rounded": "1000px",
            "width": "100%"
          },
          on: {
            "click": function click($event) {
              return _vm.$router.push(Object.assign({}, _vm.$route, {
                name: 'nutri.meal-plan.menu-recommendation.detail',
                params: {
                  bypass: 1
                }
              }));
            }
          }
        }, [_vm._v(" Konfirmasi ")])], 1)];
      },
      proxy: true
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }