<template>
  <c-box
    position="relative"
    :margin-bottom="['1rem', '2rem']"
  >
    <c-flex
      align-items="center"
      :gap="['8px', '2rem']"
    >
      <c-button
        variant="ghost"
        padding="0px"
        width="30px"
        height="30px"
        min-width="30x"
        :display="['none', 'flex']"
        align-items="center"
        @click="$router.back()"
      >
        <inline-svg
          :src="require('@/assets/icons/icon-chevron-left.svg')"
          height="20px"
          width="20px"
        />
      </c-button>
      <c-flex
        justify-content="center"
        align-items="center"
        background-color="#CDEDFC"
        :padding="['0.2rem','0.5rem']"
        width="100%"
        gap="2rem"
      >
        <c-text :font-size="['10px','14px']">
          Kamu dapat menyimpan panduan makan ke perangkat.
        </c-text>
        <c-button
          :font-size="['10px','14px']"
          font-weight="400"
          size="sm"
          variant-color="primary"
          variant="outline"
          background-color="neutral.white"
          border-radius="6px"
          :height="['15px','25px']"
          gap="0.2rem"
          padding="0 4px"
          :disabled="isDownloading"
          min-width="55px"
          @click="downloadMealPlan"
        >
          Unduh
          <c-box height="100%">
            <c-circular-progress
              v-if="isDownloading"
              :padding="['1px','4px']"
              width="100%"
              height="100%"
              is-indeterminate
              color="primary"
            />
            <inline-svg
              v-else
              :src="require('@/assets/icons/icon-save-alt.svg')"
              height="100%"
              fill="#008C81"
            />
          </c-box>
        </c-button>
      </c-flex>
    </c-flex>
    <transition name="fade">
      <c-flex
        v-if="isShowPopupSuccessDownload"
        justify-content="center"
        align-items="center"
        background-color="#C7F9E3"
        :padding="['0.2rem','0.5rem']"
        width="100%"
        gap="2rem"
        position="absolute"
        top="120%"
      >
        <c-text :font-size="['10px','14px']">
          Berhasil mengunduh panduan makan
        </c-text>
      </c-flex>
    </transition>
  </c-box>
</template>

<script>
import { CBox, CFlex } from '@chakra-ui/vue'
import download from 'downloadjs'
import {
  reqNutri_mealPlans_download,
  reqNutri_mealPlans_downloadFilename,
} from '@/requests/dietela-api/nutritionist/meal-plans'

export default {
  name: 'DownloadMealPlan',
  components: { CFlex, CBox },
  props: {
    programIdLocal: {
      type: String,
      required: true,
    },
    month: {
      type: [String, Number],
      required: true,
    },
    clientIdLocal: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isShowPopupSuccessDownload: false,
      isDownloading: false,
    }
  },
  methods: {
    async downloadMealPlan() {
      try {
        if (!this.programIdLocal || !this.month) {
          throw new Error('Program ID or Month is not defined')
        }
        this.isDownloading = true
        const resFilename = await reqNutri_mealPlans_downloadFilename(this.$store.getters.axios, {
          programId: this.programIdLocal,
          month: this.month,
          clientId: this.clientIdLocal,
        })
        const filenameWithExtention = resFilename.data?.data
        const response = await reqNutri_mealPlans_download(this.$store.getters.axios, {
          programId: this.programIdLocal,
          month: this.month,
          clientId: this.clientIdLocal,
        }, {
          responseType: 'blob',
        })
        const content = response.headers['content-type']
        download(response.data, filenameWithExtention, content)
      } catch (error) {
        this.$toast({
          title: 'Failed',
          description: 'Ops! Something when wrong.',
          status: 'error',
          duration: 5000,
          position: 'bottom-right',
          variant: 'subtle',
        })
      } finally {
        this.isDownloading = false
      }
    },
  },
}
</script>

<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
